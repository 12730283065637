@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Roboto-Regular";
    src: local("Roboto-Regular"),
        url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Light";
    src: local("Roboto-Light"),
        url("./fonts/Roboto/Roboto-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Bold";
    src: local("Roboto-Bold"),
        url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "RobotoCondensed-Regular";
    src: local("RobotoCondensed-Regular"),
        url("./fonts/Roboto/RobotoCondensed-Regular.ttf") format("truetype");
}

/* turn off these tailwind rules - breaks things */
img,
video {
    max-width: none;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Background image does not match specs, need specific image */
    background-repeat: no-repeat;
    background-position: right top;
    background-attachment: fixed;
}

input {
    vertical-align: baseline;
    /* tailwind sets color to inherit */
    color: #000000;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

button {
    cursor: pointer;
}

.form-contents-container {
    margin-top: 30px;
}

.form-row {
    margin-bottom: 20px;
}

.multi-row-input-container {
    display: flex;
    gap: 15px;
    flex-direction: row;
}

.form-buttons-container {
    margin-top: 40px;
}

.form-button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.multi-input-container {
    flex-grow: 1;
}

/* Added media queries based upon specs */
@media only screen and (min-width: 820px) {

    .form-contents-container {
        margin-top: 60px;
    }

    .form-buttons-container {
        margin-top: 72px;
    }
}